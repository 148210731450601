.ta-right {
  text-align: right;
}

.ta-center {
  text-align: center;
}

.ta-center {
  text-align: center;
}

.ta-center {
  text-align: center;
}

// padding top and bottom

.ptb-xs {
  padding: 0 0;
}

.ptb-s {
  padding: 0.5rem 0;
}

.ptb-m {
  padding: 1rem 0 !important;
}

.ptb-l {
  padding: 2rem 0;
}

.ptb-xl {
  padding: 4rem 0;
}

// padding top
.pt-xs {
  padding-top: 4px;
}

.pt-24 {
  padding-top: 24px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-s {
  padding-top: 0.5rem !important;
}

.pt-m {
  padding-top: 1rem;
}

.pt-l {
  padding-top: 2rem;
}

.pt-xl {
  padding-top: 4rem;
}

.pt-2xl {
  padding-top: 80px;
}

// padding bottom
.pb-s {
  padding-bottom: 0.5rem;
}

.pb-m {
  padding-bottom: 1rem;
}

.pb-l {
  padding-bottom: 2rem;
}

.pb-xl {
  padding-bottom: 4rem;
}

// padding left
.pl-xs {
  padding-left: 8px;
}
.pl-s {
  padding-left: 0.5rem;
}

.pl-m {
  padding-left: 1rem;
}

.pl-l {
  padding-left: 2rem;
}

.pl-xl {
  padding-left: 4rem;
}

// padding left right
.plr-s {
  padding: 0 0.5rem;
}

.plr-m {
  padding: 0 1rem;
}

.plr-l {
  padding: 0 2rem !important;
}

.plr-xl {
  padding: 0 4rem;
}

// margin

.m-auto {
  margin: auto;
}

.m-s {
  margin: 0.5rem;
}

.m-m {
  margin: 1rem;
}

.m-l {
  margin: 2rem;
}

.m-xl {
  margin: 4rem;
}

// margin left
.ml-xs {
  margin-left: 10px;
}

// margin left
.ml-s {
  margin-left: 0.5rem;
}

.ml-m {
  margin-left: 1rem;
}

.ml-l {
  margin-left: 2rem;
}

.ml-xl {
  margin-left: 4rem;
}

.mr-s {
  margin-right: 0.5rem;
}

.mr-m {
  margin-right: 1rem;
}

.mr-l {
  margin-right: 2rem;
}

// margin top and bottom

.mtb-s {
  margin: 0.5rem 0;
}

.mtb-m {
  margin: 1rem 0 !important;
}

.mtb-l {
  margin: 2rem 0;
}

.mb-s {
  margin-bottom: 0.5rem;
}

.mb-xs {
  margin-bottom: 2px;
}


.mb-m {
  margin-bottom: 1rem;
}

.mb-xxl {
  margin-bottom: 150px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-m {
  margin-top: 1rem;
}

.mt-l {
  padding-top: 24px;
}

.relative {
  position: relative;
}

.hidden {
  display: none;
}
// align items
.ai-center {
  align-items: center !important;
}

.ai-end {
  align-items: end;
}

// justify content
.jc-center {
  justify-content: center !important;
}

.jc-end {
  justify-content: flex-end;
}

// box sizing
.bs-border-box {
  box-sizing: border-box;
}

// text transform
.camel {
  text-transform: capitalize;
}

// positions
.position-right {
  position: absolute;
  right: 0;
}

// width
.w-100 {
  width: 100px !important;
}

.w-200 {
  width: 200px !important;
}

// border
.border-1 {
  border: 1px solid #e0e0e0;
}
// justify content
.jc-center {
  justify-content: center !important;
}

// box sizing
.bs-border-box {
  box-sizing: border-box;
}

// text transform
.camel {
  text-transform: capitalize;
}

// positions
.position-right {
  position: absolute;
  right: 0;
}

// width
.w-100 {
  width: 100px !important;
}

.w-200 {
  width: 200px !important;
}

// border
.border-1 {
  border: 1px solid #e0e0e0;
}

.hide-helper {
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

.module-header-actions-custom {
  gap: 0 !important;

  .mdc-text-field {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}

.module-header-actions-compact {
  gap: 1rem !important;
  margin-right: 0 !important;
}

.custom-field {
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;

  .mat-mdc-form-field-flex {
    height: 100%;

    .mat-mdc-input-element {
      height: 100% !important;
      display: flex;
    }
  }

  .mat-mdc-form-field-infix {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    min-height: unset;
    max-height: 100% !important;
  }
}

.w-l {
  width: 300px !important;
}

.header-label {
  color: #272d36;
  font-size: $font-size-small;
  line-height: 24px;
  font-weight: bold;
}

.custom-select {
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;

  .mat-mdc-select-trigger {
    height: 100%;
  }
}

.flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.flex-col {
  flex-direction: column;
}

.va-top {
  vertical-align: top;
}

.dialog-content-wrapper {
  height: 100%;
  overflow-y: auto;
  padding-bottom: 80px;
}
